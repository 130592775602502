import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {emphasize, fade, darken, lighten} from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';
import {blue, purple, red, green} from '@material-ui/core/colors';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AirplayIcon from '@material-ui/icons/Airplay';
import CropIcon from '@material-ui/icons/Crop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoneyOff from '@material-ui/icons/MoneyOff';
import Security from '@material-ui/icons/Security';
import CenterFocusStrongSharp from '@material-ui/icons/CenterFocusStrongSharp';

const useStyles = makeStyles((theme) => ({
        icon1: {
            color: blue[600],
        },
        icon2: {
            color: purple[600],
        },
        icon3: {
            color: red[600],
        },
        icon4: {
            color: green[600],
        },
        features: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(10),
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderColor: theme.palette.background.emphasis,
            }
        },
        iconWrapper: {
            backgroundColor: fade(theme.palette.background.emphasis, .6),
        },
    }
));

export default function Component(props) {
    const classes = useStyles();
    function takeMeUp(event) {
        event.stopPropagation();
        event.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>

            <section>

                <Container maxWidth="lg">
                    <Box py={6} style={{marginBottom: '60px'}}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box textAlign="center">
                                    <Box mb={2}>
                                        <AirplayIcon fontSize="large" className={classes.icon1}/>
                                    </Box>
                                    <Typography variant="subtitle1" component="p" color="textSecondary">Screenshot Full Web Page</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box textAlign="center">
                                    <Box mb={2}>
                                        <CropIcon fontSize="large" className={classes.icon2}/>
                                    </Box>
                                    <Typography variant="subtitle1" component="p" color="textSecondary">Crop It On The Fly</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box textAlign="center">
                                    <Box mb={2}>
                                        <CloudDownloadIcon fontSize="large" className={classes.icon3}/>
                                    </Box>
                                    <Typography variant="subtitle1" component="p" color="textSecondary">Download It As PNG</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </section>
            <section id={"features"}>
                <Container maxWidth="lg">
                    <Box pt={10} pb={12}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="overline" color="textSecondary">Skreenshot.website</Typography>
                                <Typography variant="h3" component="h2" gutterBottom={true}>
                                    <Typography color="primary" variant="h3" component="span">Take web page screenshots,</Typography>
                                    <Typography variant="h3" component="span"> crop on the fly, and download them easily!</Typography>
                                </Typography>
                                <Typography variant="subtitle1" component="p" color="textSecondary">
                                    Enter the link of a publicly available web page, hit the shoot button, give it around 10 seconds, then your screenshot is ready to be cropped & saved locally on your machine.
                                </Typography>
                                <Box item mt={3} md={8} s={12}>
                                    <Button style={{width:'40%',fontSize:'20px'}} onClick={takeMeUp}  variant="contained" color="secondary">Give it a try!</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className={classes.features}>
                                    <Box display="flex" mb={5}>
                                        <Box pr={5}>
                                            <Avatar className={classes.iconWrapper} variant="rounded">
                                                <MoneyOff color="primary" />
                                            </Avatar>
                                        </Box>
                                        <div>
                                            <Typography variant="h6" component="h3" gutterBottom={true}> No Pricing Page!</Typography>
                                            <Typography variant="body1" component="p" color="textSecondary">We believe that capturing screenshots of publicly available web pages is a helpful tool, and it should be available for everyone FOR FREE.</Typography>
                                        </div>
                                    </Box>
                                    <Box display="flex" mb={5}>
                                        <Box pr={5}>
                                            <Avatar className={classes.iconWrapper} variant="rounded">
                                                <Security color="primary" />
                                            </Avatar>
                                        </Box>
                                        <div>
                                            <Typography variant="h6" component="h3" gutterBottom={true}>Safe And Secure Web Experience!</Typography>
                                            <Typography variant="body1" component="p" color="textSecondary">We are using HTTPS encryption for assuring data integrity between browsers and servers, so we provide users with a safe and secure web experience.</Typography>
                                        </div>
                                    </Box>
                                    <Box display="flex">
                                        <Box pr={5}>
                                            <Avatar className={classes.iconWrapper} variant="rounded">
                                                <CenterFocusStrongSharp color="primary" />
                                            </Avatar>
                                        </Box>
                                        <div>
                                            <Typography variant="h6" component="h3" gutterBottom={true}>Reliable!</Typography>
                                            <Typography variant="body1" component="p" color="textSecondary">We can capture any web pages that are available publicly in full-screen mode! excluding the pages that require login/credentials.</Typography>
                                        </div>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </section>
            <hr/>
        </>
    );
}