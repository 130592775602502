import React from 'react';

import HeadersCustom1 from '../components-custom/HeadersCustom1';
import FeaturesCustom2 from '../components-custom/FeaturesCustom2';
import FootersCustom3 from '../components-custom/FootersCustom3';

export default class Index extends React.Component {

    render() {
        return (
            <React.Fragment>
                <HeadersCustom1/>

                <FeaturesCustom2/>

                <FootersCustom3/>
            </React.Fragment>
        );
    }


}

