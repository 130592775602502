import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
        footerNav: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1)
        },
        footerLink: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(2),
        }
    }
));

export default function Component(props) {
    const classes = useStyles();

    return (
        <footer id={"footer"}>
            <Container maxWidth="lg">
                <Box py={6} textAlign="center">
                    <Box mb={5}>
                        <Typography variant="h6" component="h6" >Follow/Contact Me On Social Media!</Typography>
                        <a href='https://twitter.com/IbraAbuKaff'><IconButton target='_blank' color="inherit" aria-label="Twitter" href='https://twitter.com/IbraAbuKaff'>

                            <TwitterIcon href='https://twitter.com/IbraAbuKaff'/>

                        </IconButton></a>
                        <a href='https://www.linkedin.com/in/ibraheem-z-abu-kaff'><IconButton target='_blank' color="inherit" aria-label="LinkedIn" href='https://www.linkedin.com/in/ibraheem-z-abu-kaff'>

                            <LinkedInIcon href='https://www.linkedin.com/in/ibraheem-z-abu-kaff'/>

                        </IconButton></a>
                    </Box>
                    <Typography color="textSecondary" component="p" variant="caption" gutterBottom={false} style={{fontSize: '23px'}}>© 2020 <a href={'/'}>Skreenshot.website </a>• Made with ❤️ in Dubai</Typography>
                </Box>
            </Container>
        </footer>
    );
}