import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {AppBar, CircularProgress, List, ListItem, ListItemText, Toolbar} from '@material-ui/core';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Grid from '@material-ui/core/Grid';
import CloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';

const useStyles = makeStyles((theme) => ({
        section: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(30),
            }
        },
        textLogo: {
            [theme.breakpoints.up('md')]: {
                fontSize: '38px'
            },
        },
        textSlogan: {
            [theme.breakpoints.up('md')]: {
                fontSize: '38px'
            },
        },
        shootBtn: {
            width: '200px',
            height: '55px',
            fontSize: '25px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '15px',
                width: '80px',
            },
        },
        successAlert: {
            fontSize: '25px',
            marginTop: '13.5px',
            marginBottom: '20px',
            fontWeight: '400',
            [theme.breakpoints.down('xs')]: {
                fontSize: '15px',
                marginBottom: '0px',
            },
        },
        downloadBtn: {
            margin: '14px auto',
            width: '100%',
            // height: '50px',
            fontSize: '20px',
            fontWeight: '400',
            [theme.breakpoints.down('xs')]: {
                fontSize: '15px',
                margin: '0px auto',
            },

        },
        navDisplayFlex: {
            display: `flex`,
            justifyContent: `space-between`,
        },
        linkText: {
            textDecoration: `none`,
            color: `white`,
            paddingTop: '10px'
        }
    }
));

export default function Component(props) {
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageStringBase64, setImageStringBase64] = useState('');
    const [loading, setLoading] = useState(false);


    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();

    const downloadCroppedData = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (typeof cropper !== "undefined") {
            const croppedData = cropper.getCroppedCanvas().toDataURL('image/png', 1.0)
            setCropData(croppedData);
            const anchorToDownload = document.getElementById('downloadImage')
            anchorToDownload.href = croppedData
            anchorToDownload.download = new Date().toLocaleString().replace(',', '') + '-screenshot.png'
            anchorToDownload.click()
        }
    };

    async function fetchScreenshot(event) {
        if (loading) {
            return false;
        }

        event.preventDefault();
        event.stopPropagation();
        try {
            if (url.trim() === '') {
                throw new Error('Please Enter A URL ...')
            }
            const expression = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/gi;
            const regex = new RegExp(expression);
            if (!url.match(regex)) {
                throw new Error('Please Enter A Valid URL')
            }
            setHasError(false)
            setImageStringBase64('')
            setLoading(true)
            const body = JSON.stringify({
                'main_sleep': 10,
                'url': encodeURIComponent(url.trim())
            })
            const apiURL = `https://api.skreenshot.website`
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'X-Api-Key': 'xtgyir783N4xrjUMlXjAY24x47x94nZt4hhQcKRS',
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body
            })
            if (!response.ok) {
                throw new Error('Sorry, We could not screenshot that web page, Please try again.')
            }
            const jsonResponse = await response.json()
            const imageAsStr = jsonResponse['data']
            if (imageAsStr === undefined || imageAsStr === '') {
                setImageStringBase64('')
                throw new Error('Sorry, We could not screenshot that web page, Please try again.')
            }
            const getImagesSource = (src) => {
                return `data:image/png;base64,${src}`
            }
            setImageStringBase64(getImagesSource(imageAsStr))
        } catch (e) {
            setHasError(true)
            setErrorMsg(e.message || 'Something Went Wrong, Please try again.')
        }
        setLoading(false)
    }

    async function fetchScreenshotOnEnter(event) {
        if (event.keyCode === 13 && !loading) {
            await fetchScreenshot(event);
        }
    }

    function onChange(e) {
        setUrl(e.target.value)
    }

    function scrollTo(path,samePage) {
        if (samePage){
            const anchor = document.querySelector(path);
            anchor.scrollIntoView({behavior: 'smooth'});
        } else {
            window.open(path, '_blank', 'noopener,noreferrer')
        }
    }

    const navLinks = [
        {title: `About`, path: `#features`, samePage:true},
        {title: `Contact`, path: `#footer`,samePage:true},
        {title: `Privacy Policy`, path: `/privacy-policy`,samePage:false},
    ]

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Container className={classes.navDisplayFlex}>
                        <a href='/' style={{textDecoration: 'none', color: 'white', marginTop: '9px'}}><Typography className={classes.textLogo}>
                            <ListItem button> {'Skreenshot.website'}</ListItem>
                        </Typography></a>

                        <List component="nav" aria-labelledby="main navigation" className={classes.navDisplayFlex}>
                            {navLinks.map(({title, path,samePage}) => (
                                <a href={path} onClick={(eve) => {
                                    eve.stopPropagation();
                                    eve.preventDefault();
                                    scrollTo(path,samePage)
                                }} key={title} className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary={title}/>
                                    </ListItem>
                                </a>
                            ))}
                        </List>
                    </Container>
                </Toolbar>
            </AppBar>
            <section className={classes.section} style={{paddingTop: '180px', backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")'}}>
                <Container maxWidth="lg">
                    <Box textAlign="center">
                        <Grid item xs={12}>
                            <a href='#' style={{textDecoration: 'none'}}><Typography className={classes.textSlogan} color="textSecondary">Take Webpage Screenshots, Crop And Download Them Easily!</Typography></a>
                        </Grid>
                        <Box mt={3} xs={12} sm={6} md={8}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <OutlinedInput
                                    onKeyUp={fetchScreenshotOnEnter}
                                    onChange={onChange}
                                    placeholder="Enter Public URL like ... https://google.com"
                                    style={{width: '100%'}}
                                    endAdornment={<InputAdornment position="end">
                                        <Button className={classes.shootBtn} disabled={loading} onClick={fetchScreenshot} variant="contained" color="primary">
                                            Shoot!
                                        </Button>

                                    </InputAdornment>}
                                />
                            </FormControl>
                        </Box>
                        {hasError ? <Alert style={{fontSize: '24px', marginTop: '5px'}} severity="error">{errorMsg}</Alert> : <></>}

                        <a style={{display: 'none'}} id={"downloadImage"}/>
                        {
                            loading ?
                                <CircularProgress style={{marginTop: '20px'}}/>
                                : <></>
                        }

                        <div style={{marginTop: '20px'}}>
                            {
                                imageStringBase64 === '' || imageStringBase64 === undefined ?
                                    <></> :
                                    <>
                                        <Grid container spacing={2} style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                <Alert className={classes.successAlert} container spacing={3} variant="filled" severity="success">
                                                    Crop The Screenshot According To Your Needs, Then Click The Download Button
                                                </Alert>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{fontWeight: '400'}}>
                                                <Button className={classes.downloadBtn} startIcon={<CloudDownloadRounded/>} onClick={downloadCroppedData} variant="contained" color="secondary">
                                                    Download Selected Area
                                                </Button>
                                            </Grid>


                                        </Grid>
                                        <Grid item xs={12}>
                                            <Cropper
                                                src={imageStringBase64}
                                                viewMode={1}
                                                guides={true}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                zoomable={false}
                                                checkOrientation={false}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                            />
                                            <Grid item xs={12}>
                                                <hr/>

                                            </Grid>
                                        </Grid>

                                    </>
                            }
                        </div>
                    </Box>
                </Container>
            </section>
        </>
    );
}