import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    "palette": {
        "background": {
            "default": "#f8f7ff",
            "emphasis": "#f8f7ff",
            "secondary": "#9381ff",
            "paper": "#f8f7ff",
            "header": "#f8f7ff"
        },
        "text": {
            "primary": "#4747ff",
            "secondary": "#9381ff",
            "hint": "#9FA8DA"
        },
        "contrastThreshold": 1.8,
        "primary": {
            "main": "#304FFE",
            "light": "#536DFE",
            "dark": "#1A237E"
        },
        "secondary": {
            "main": "#FFAB00",
            "light": "#ffd740",
            "dark": "#ff8f00"
        }
    },
    "typography": {
        "fontFamily": "\"Alegreya Sans\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
        "fontSize": "38px",
        "h1": {
            "fontSize": "50px",
            "fontWeight": 900
        },
        "body1": {
            "fontSize": "25px"
        },
        "body2": {
            "fontSize": "38px",
            "fontWeight": 700
        },
        "h4": {
            "fontSize": "45px",
            "fontWeight": 1000
        },
        "h6": {
            "fontSize": "25px"
        }
    },
});

export default theme;

